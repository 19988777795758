import { useRouter } from 'next/router';
import {
    useEffect, useState, useRef, useCallback
} from 'react';
import { getDimensions, eventDimension } from '../../hybrid/wrappers';
import { getIsSafari } from './utils';
const project = require('../../scripts/configs/project');

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(null);

    useEffect(() => {
        function handleResize() {
            const { width } = getDimensions();
            setWindowDimensions(width);
        }
        handleResize();
        eventDimension(handleResize);
        return () => eventDimension(handleResize, false);
    }, []);

    return windowDimensions;
};

export const useWindowDimensionsHeight = () => {
    const [windowDimensions, setWindowDimensions] = useState();

    useEffect(() => {
        function handleResize() {
            const { height } = getDimensions();
            setWindowDimensions(height);
        }
        handleResize();
        eventDimension(handleResize);
        return () => eventDimension(handleResize, false);
    }, []);

    return windowDimensions;
};

export const useWindowScrollDimensions = (func, remove) => {
    !!remove ? window.removeEventListener('scroll', func) : window.addEventListener('scroll', func);

    return () => {
        window.removeEventListener('scroll', func);
    };
};

export const unscrollableBody = (on, turnOffResize) => {
    const router = useRouter()
    const turnOn = () => document.querySelector('html').classList.add('uscrollable');
    const turnOff = () => document.querySelector('html').classList.remove('uscrollable');
    useEffect(() => {
        if (on){
            turnOn()
        } else {
            turnOff()
        }
        return () => {
            turnOff()
        }
    }, [on]);
    useEffect(() => {
        turnOff()
    }, [router]);

    useEffect(() => {
        if (turnOffResize){
            const resetMenuEffect = () => {
                turnOff()
                turnOffResize(false);
            };

            window.addEventListener('resize', resetMenuEffect, false);

            return () => {
                window.removeEventListener('resize', resetMenuEffect, false);
                turnOff()
            };
        }
    }, [turnOffResize]);
    return on;
}

export const getBoundingTopSafari = (element) => {
    // TODO ZOOM
    const picker = element.getBoundingClientRect();
    let isSafari = getIsSafari();
    if (isSafari) {
        var getWithZoom = (scroll) => {
            let width = window.innerWidth;
            const zoom = Number(window.getComputedStyle(document.querySelector('html'), null).zoom);
            if (project.zooming && zoom === zoom && zoom < 1 && width < 1650 && width >= 1300) {
                return scroll / zoom;
            }
            return scroll;
        };
        return picker.top - (getWithZoom(window?.scrollY) - window?.scrollY);
    }
    // TODO ZOOM
    return picker.top;
};

export const useUnscrollableBody = (on, key, turnOffResize, unscrollable = true) => {
    const scrollValue = useRef();

    const turnOn = useCallback(() => {
        const prevData = document.querySelector('html').dataset.uscrollable;
        const prevDataArr = prevData ? prevData.split(',') : [];

        if (window.innerWidth < 768) {
            const scroll = scrollY;

            document.querySelector('html').dataset.uscrollable = [...prevDataArr, key].join(',');
            document.querySelector('body').scrollTo({
                top: scroll,
                left: scrollX,
                behavior: 'instant'
            });
            scrollValue.current = scroll;
        } else {
            const padding = window.innerWidth - document.documentElement.clientWidth;
            document.querySelector('html').dataset.uscrollable = [...prevDataArr, key].join(',');
            if (padding) {
                document.querySelector('html').style.paddingRight = `${padding}px`;
            }
        }
    }, [key]);

    const turnOff = useCallback(() => {
        const prevData = document.querySelector('html').dataset.uscrollable;
        const prevDataArr = prevData && prevData.split(',');
        const newData = prevDataArr && prevDataArr.filter((i) => i !== key);

        if (newData && newData?.length) {
            document.querySelector('html').dataset.uscrollable = newData.join(',');
        } else {
            document.querySelector('html').style.paddingRight = '';
            document.querySelector('html').removeAttribute('data-uscrollable');
            if (window.innerWidth < 768 && scrollValue.current) {
                document.querySelector('html').scrollTo({
                    top: scrollValue.current,
                    left: scrollX,
                    behavior: 'instant'
                });
            }
        }
    }, [key]);

    useEffect(() => {
        if (unscrollable) {
            if (on) {
                turnOn();
            } else {
                turnOff();
            }
            return () => {
                turnOff();
            };
        }
    }, [on, unscrollable, turnOn, turnOff]);

    useEffect(() => {
        if (unscrollable) {
            if (turnOffResize) {
                const resetMenuEffect = () => {
                    turnOff();
                    turnOffResize(false);
                };

                window.addEventListener('resize', resetMenuEffect, false);

                return () => {
                    window.removeEventListener('resize', resetMenuEffect, false);
                    turnOff();
                };
            }
        }
    }, [turnOffResize, turnOff, unscrollable]);
    return on;
};

export const useDisableClickOnDrag = (onNotScroll, vertical = false) => {
    const touchStartX = useRef(0);
    const touchStartY = useRef(0);
    const scrolling = useRef(false);

    const mouseStartX = useRef(0);
    const mouseStartY = useRef(0);

    const handleTouchStart = useCallback((event) => {
        const touch = event.touches[0];
        if (touch) {
            touchStartX.current = touch.clientX;
            touchStartY.current = touch.clientY;
            scrolling.current = false;
        }
    }, []);

    const handleTouchMove = useCallback((event) => {
        const touch = event.touches && event.touches[0];
        if (touch) {
            const deltaX = Math.abs(touch.clientX - touchStartX.current);
            const deltaY = Math.abs(touch.clientY - touchStartY.current);

            const isScrolling = vertical ? deltaX < deltaY : deltaX > deltaY;
            if (isScrolling) {
                scrolling.current = true;
            } else {
                scrolling.current = false;
            }
        }
    },
    [vertical]);
    const handleTouchEnd = useCallback((event) => {
        if (!scrolling.current && onNotScroll) {
            onNotScroll(event);
        }
    },
    [onNotScroll]);
    const handleMouseDown = useCallback((event) => {
        mouseStartX.current = event.clientX;
        mouseStartY.current = event.clientY;
    }, []);
    const handleMouseUp = useCallback((event) => {
        const deltaX = Math.abs(event.clientX - mouseStartX.current);
        const deltaY = Math.abs(event.clientY - mouseStartY.current);

        const isScrolling = vertical ? deltaX < deltaY : deltaX > deltaY;
        if (!isScrolling && onNotScroll) {
            onNotScroll(event);
        }
    },
    [onNotScroll, vertical]);
    const handleClick = useCallback((event) => {
        const deltaX = Math.abs(event.clientX - mouseStartX.current);
        const deltaY = Math.abs(event.clientY - mouseStartY.current);

        const isScrolling = vertical ? deltaX < deltaY : deltaX > deltaY;

        if (scrolling.current || isScrolling) {
            event.preventDefault();
        }
    },
    [vertical]);
    return {
        allEvents: {
            onMouseDown: handleMouseDown,
            onMouseUp: handleMouseUp,
            onTouchStart: handleTouchStart,
            onTouchMove: handleTouchMove,
            onTouchEnd: handleTouchEnd,
            onClick: handleClick
        },
        handleClick,
        handleMouseUp,
        handleMouseDown,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd
    };
};

export const usePopupListeners = (
    pickerElement,
    onScrollHandler,
    modalElement,
    handleClose,
    disableListeners = false,
    otherDeps = false
) => {
    const onNotScroll = useCallback((event) => {
        if (event.changedTouches) {
            const touchEndX = event.changedTouches[0].clientX;
            const touchEndY = event.changedTouches[0].clientY;

            function isInsideElement(element) {
                const elementRect = element.getBoundingClientRect();

                return (
                    touchEndX >= elementRect.left &&
                        touchEndX <= elementRect.right &&
                        touchEndY >= elementRect.top &&
                        touchEndY <= elementRect.bottom
                );
            }
            if (pickerElement.current && isInsideElement(pickerElement.current)) {
                return;
            }
            if (modalElement.current && isInsideElement(modalElement.current)) {
                return;
            }

            handleClose();
        }
    },
    [handleClose, modalElement, pickerElement]);
    const {
        handleTouchStart, handleTouchMove, handleTouchEnd
    } = useDisableClickOnDrag(onNotScroll, true);

    useEffect(() => {
        if (pickerElement.current) {
            const onClickOutsideFunc = (e) => {
                var getWithZoom = (mouse) => {
                    let width = window.innerWidth;
                    const zoom = Number(window.getComputedStyle(document.querySelector('html'), null).zoom);
                    if (zoom === zoom && zoom < 1 && width < 1650 && width >= 1300) {
                        return mouse / zoom;
                    }
                    return mouse;
                };
                var clickX = getWithZoom(e.clientX);
                var clickY = getWithZoom(e.clientY);

                function isInsideElement(element) {
                    var elementRect = element.getBoundingClientRect();
                    var elementX = elementRect.left;
                    var elementY = getBoundingTopSafari(element);
                    var elementWidth = elementRect.width;
                    var elementHeight = elementRect.height;

                    if (
                        clickX >= elementX &&
                        clickX <= elementX + elementWidth &&
                        clickY >= elementY &&
                        clickY <= elementY + elementHeight
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }

                if (pickerElement.current && isInsideElement(pickerElement.current)) {
                    return;
                }
                if (modalElement.current && isInsideElement(modalElement.current)) {
                    return;
                }
                handleClose();
            };
            const scrollOn = pickerElement.current.closest('[data-scroll]') || window;

            if (disableListeners) {
                if (onScrollHandler) {
                    scrollOn.removeEventListener('scroll', onScrollHandler);
                }
                document.body.removeEventListener('click', onClickOutsideFunc);

                document.removeEventListener('touchstart', handleTouchStart);
                document.removeEventListener('touchmove', handleTouchMove);
                document.removeEventListener('touchend', handleTouchEnd);
            } else {
                if (onScrollHandler) {
                    scrollOn.addEventListener('scroll', onScrollHandler);
                }
                document.body.addEventListener('click', onClickOutsideFunc);

                document.addEventListener('touchstart', handleTouchStart);
                document.addEventListener('touchmove', handleTouchMove);
                document.addEventListener('touchend', handleTouchEnd);
            }

            return () => {
                if (onScrollHandler) {
                    scrollOn.removeEventListener('scroll', onScrollHandler);
                }
                document.body.removeEventListener('click', onClickOutsideFunc);

                document.removeEventListener('touchstart', handleTouchStart);
                document.removeEventListener('touchmove', handleTouchMove);
                document.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, [
        onScrollHandler,
        handleClose,
        modalElement,
        disableListeners,
        pickerElement,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd
    ]);

    useEffect(() => {
        if (onScrollHandler) {
            onScrollHandler();
        }
    }, [otherDeps, onScrollHandler]);

    return null;
};